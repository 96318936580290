"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useState } from "react";
import * as pixel from "@/lib/meta-pixel";

const MetaPixel = () => {
  const [loaded, setLoaded] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (!loaded) return;

    pixel.pageview();
  }, [pathname, loaded]);

  // We're only setting Pixel env var on production right now.
  if (!pixel.FB_PIXEL_ID) return null;

  return (
    <Script
      id="fb-pixel"
      src="/scripts/meta-pixel.js"
      strategy="afterInteractive"
      onLoad={() => setLoaded(true)}
      data-pixel-id={pixel.FB_PIXEL_ID}
    />
  );
};

export default MetaPixel;
