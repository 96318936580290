import { FieldErrors } from "react-hook-form";

export const isBrowser = () => {
  return typeof window !== "undefined";
};

export const getLeadSource = () => {
  const defaultSource = "HomePage";
  if (typeof window === "undefined") return defaultSource;
  const leadSource = window.location.pathname;
  return leadSource || defaultSource;
};

export const getUTMParam = (param: string) => {
  if (typeof window === "undefined") return "";
  const params = new URLSearchParams(window.location.search);
  return params.get(param) || "";
};

export const checkFormError = (errors: FieldErrors, id: string) => {
  if (errors[id] && errors[id].message) {
    return errors[id].message as string;
  }
  return "";
};

export const formatPhoneNumber = (phone: string) => {
  return phone ? phone.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3") : "";
};

export const downloadBase64File = (base64: string, fileName: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Convert to a Blob
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray]);

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  // Clean up the URL Object
  URL.revokeObjectURL(link.href);
};

export const checkBooleanExists = (value: any) => {
  return value === true || value === false;
};

export const inchesToHeight = (value: string) => {
  const numInches = parseInt(value, 10);

  if (isNaN(numInches)) {
    return "";
  }

  const feet = Math.floor(numInches / 12);
  const remainderInches = numInches % 12;
  return `${feet}' ${remainderInches}"`;
};
